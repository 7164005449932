.wordpress .wp-block-heading{
    font-size: 1.0em;
    font-weight: bold;
    margin-bottom: 1em;
}

.wordpress li{
    margin-bottom: 1em;
    display: list-item;
    list-style-type: circle;
    margin-left: 10%;
}

.wordpress p{
    margin-bottom: 1em;
    margin-left: 2%;
    margin-right: 2%;
}