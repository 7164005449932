/* https://www.youtube.com/watch?v=siwpn14IE7E */
.dangerously ol {
    list-style-type: decimal;
}
.dangerously ul {
    list-style-type: circle;
}
.dangerously li {
    margin-bottom: 1em;
    display: list-item;

    margin-left: 10%;
    text-align: left;
}

.dangerously p{
    margin-bottom: 1em;
    margin-right: 2%;
    text-align: left;
}

.dangerously div{
    width: 100%;
}

.dangerously h2 {
    margin: auto;
    font-weight: bold;
}

.dangerously h1 {
    margin: auto;
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.25em;
}
.dangerously img {
    margin: auto;
}
.dangerously  {
    width: 100%;
    text-align: center;
    vertical-align: middle;

}

.dangerouslyLT li{
    margin-bottom: 1em;
    display: list-item;
    list-style-type: circle;
    margin-left: 10%;
}

.dangerouslyLT div{
    width: 100%;
}

.dangerouslyLT blockquote{
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    padding: 5px;
    border-radius: 10px;
    background-color: beige;
}

.dangerouslyLT img {
    margin: auto;
}

.dangerouslyLT h2 {
    margin: auto;
    font-weight: bold;
}

.dangerouslyLT h1 {
    margin: auto;
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.25em;
}

.dangerouslyLT {
    width: 100%;
    padding-left: 5%;
}