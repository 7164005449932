/* RefinementList container */
.ais-RefinementList {
  font-family: "Arial", sans-serif;
  padding: 15px;
}

.ais-SearchBox-input {
  margin-bottom: 10px;
  width: 100%;
}

/* RefinementList items */
.ais-RefinementList-item {
  list-style-type: none;
  padding: 8px 0;
}

/* RefinementList checkboxes */
.ais-RefinementList-checkbox {
  margin-right: 10px;
  cursor: pointer;
}

/* RefinementList item labels */
.ais-RefinementList-label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.ais-RefinementList-list {
  margin-bottom: 10px;
}
/* RefinementList count */
.ais-RefinementList-count {
  font-size: 12px;
  color: #7696a2;
  margin-left: 5px;
}

/* RefinementList item when selected */
.ais-RefinementList-item--selected .ais-RefinementList-label {
  font-weight: bold;
  color: #216478;
}

/* RefinementList item when selected */
.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
  border-color: #216478;
}

.ais-CurrentRefinements {
  font-size: 16px;
  color: #216478;
  margin-top: 6px;
  margin-bottom: 16px;
  font-weight: 600;
  margin-right: 4px;
  display: none;
}

.ais-CurrentRefinements-item {
  margin-top: 8px;
  margin-bottom: 14px;
}

.ais-CurrentRefinements-category {
  margin-top: 6px;
  margin-left: 6px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
}

.ais-RefinementList-labelText {
  text-transform: capitalize;
  font-size: 12px;
}

.ais-CurrentRefinements-delete {
  color: red;
  margin-left: 4px;
}

.ais-CurrentRefinements-categoryLabel {
  font-size: 16px;
  color: #216478;
  margin-left: 2px;
  margin-right: 2px;
  margin-left: 4px;
}

.ais-CurrentRefinements-label {
  text-transform: uppercase;
}

.ais-ClearRefinements-button {
  text-transform: uppercase;
  font-size: 10px;
  background-color: rgba(33 100 120);
  color: white;
  width: 100%;
  padding: 8px 0 8px 0;
  border-radius: 0.25rem;
}

.ais-Pagination-item {
  max-height: 22px;
  min-height: 22px;
  width: 22px;
  padding-top: 2px;
  font-size: 0.8rem;
  font-weight: bold;
  border: 1px solid #216478;
  border-radius: 4px;
  color: #216478;
  margin: 0 4px 0 4px;
}

.ais-Pagination-list {
  display: flex;
}

.ais-Pagination-link {
  padding: 6px;
}

.ais-HitsPerPage-select {
  padding-right: 30px;
  font-weight: bold;
  font-size: 0.8rem;
}

.ais-Pagination-item--selected {
  background-color: white;
  border: solid 1px #216478;
  color: #216478;
  scale: 1.25;
}

.ais-RefinementList-checkbox {
  color: #216478;
}
