@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-family: Lato, system-ui, sans-serif;
  }
  [type="text"],
  [type="email"],
  [type="password"],
  select,
  textarea {
    @apply shadow-md;
    @apply text-instant-teams-gray-D1;
    @apply border-none;
    @apply shadow-input-field;
    @apply rounded-md;
    @apply focus:border-instant-teams-blue-Main;
    @apply focus:ring-1;
    @apply px-3;
  }
}

/* Header titles are in Ubuntu, reference style guide
  everything else is in Lato */

@layer components {
  .header-title {
    font-family: Ubuntu, sans-serif;
  }

  .hazard-background {
    height: 30px;
    color: white;
    padding: 5px;
    background-image: repeating-linear-gradient(
      -55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px
    );
  }
}
